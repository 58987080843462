.cta {
  position: fixed;
  bottom: 37px;
  display: flex;
  background: #fe6b37;
  padding-bottom: env(safe-area-inset-bottom);
  border-radius: 200px;
  max-width: 600px;
  width: calc(min(100%, 600px) * 0.8);
  height: calc(56px + env(safe-area-inset-bottom));
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  font-size: 20px;
  letter-spacing: -3%;
  font-weight: 500;
  transition:
    width 0.3s cubic-bezier(0.2, 0.8, 0.2, 1),
    transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1),
    box-shadow 0.5s cubic-bezier(0.2, 0.8, 0.2, 1),
    border-radius 0.15s cubic-bezier(0.2, 0.8, 0.2, 1),
    bottom 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  &.docked {
    border-radius: 0px;
    bottom: -2px;
    box-shadow: none;
    width: min(100%, 600px);
    transition:
      width 0.3s cubic-bezier(0.2, 0.8, 0.2, 1),
      border-radius 0.15s cubic-bezier(0.2, 0.8, 0.2, 1),
      bottom 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
}

.cta-spacer {
  height: calc(56px + env(safe-area-inset-bottom));
  width: 100%;
  background-color: #242426;
}
.content {
  flex: 1;
  text-align: center;
}

.btn-cs-center {
  background: #ffe1d7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
}

@media screen and (min-width: 992px) {
  .cta {
    max-width: 450px;
    padding-bottom: 0px;
    width: calc(min(100%, 450px) * 0.8);
    transform: translate(calc(-50% + 160px), 0);
    width: min(80%, 600px);
    transition:
      width 0.3s cubic-bezier(0.2, 0.8, 0.2, 1),
      transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1),
      box-shadow 0.5s cubic-bezier(0.2, 0.8, 0.2, 1),
      border-radius 0.15s cubic-bezier(0.2, 0.8, 0.2, 1),
      bottom 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
}

@keyframes slidePhrase {
  0% {
    transform: translateY(-42px);
  }
  35% {
    transform: translateY(-42px);
  }
  50% {
    transform: translateY(-2px);
  }
  85% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(42px);
  }
}
.btn-join {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 215px;
  color: white;
  border: 1px solid #fe6b37;
  background: #fe6b37;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  overflow: hidden;
  .overlay {
    position: absolute;
    width: 160px;
    z-index: 1;
    height: 100%;
    background: linear-gradient(0deg, #fe6b37 0%, transparent 30%, transparent 70%, #fe6b37 100%);
  }
  .signUpCatchPhrase {
    line-height: 40px;
    display: flex;
    flex-direction: column;
    animation: slidePhrase 4.5s linear infinite;
    transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    span {
      vertical-align: middle;
      height: 40px;
      display: grid;
      place-content: center;
    }
  }
}
