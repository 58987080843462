.subHeader {
  color: white;
  text-align: center;
  font-family: Noto Sans CJK KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  bottom: 32px;
  z-index: 8;
  left: 50%;
  transform: translateX(-50%) translateY(200px);
  width: 480px;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.show {
    transform: translateX(-50%);
  }
}
.input {
  height: 32px;
  outline: none;
  height: 60px;
  border-radius: 5px;
  width: 100%;
  flex: 1;
  font-size: 18px;
  color: #333;
  border: none;
  background: transparent;
  caret-color: #fe6b37;
  flex: 1;
  &::placeholder {
    color: #ccc;
  }
}

.simple-signup {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 64px;
  padding-left: 12px;
  border: solid 2px #fe6b37;
  background: #ffffffee;
  backdrop-filter: blur(8px);
  border-radius: 64px;
  overflow: hidden;
  .submit {
    background: #fe6b37ee;
    color: white;
    display: inline-flex;
    justify-content: center;

    font-size: 18px;
    font-weight: bold;
    align-items: center;
    width: max-content;
    padding: 0px 12px;
    padding-right: 24px;
  }
}
